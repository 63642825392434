<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <div class="top-header flex-middle">
        <div class="header-between flex-item"></div>
        <div class="header-center">
          {{ type == "1" ? $t("LP挖礦") : $t("單幣挖礦") }}
        </div>
        <wallet-contract></wallet-contract>
      </div>
    </div>
    <div class="top-row flex-between">
      <div class="top-row-left">
        <div class="text1 primary-color">{{ $t("總鎖倉量") }}</div>
        <div class="text2 primary-color">${{ totalStakeUSDT || "--" }}</div>
        <div class="row-btn-box flex-middle">
          <div
            class="btn-item bg-primary mr"
            :class="{ 'bg-orange': type === '1' }"
            @click="$router.replace({ name: 'MiningLp', query: { type: '1' } })"
          >
            {{ $t("LP挖礦") }}
          </div>
          <div
            class="btn-item bg-primary"
            :class="{ 'bg-orange': type === '2' }"
            @click="$router.replace({ name: 'MiningLp', query: { type: '2' } })"
          >
            {{ $t("單幣挖礦") }}
          </div>
        </div>
      </div>
      <div class="top-row-right">
        <img
          v-if="type === '1'"
          class="item-icon"
          src="~@/assets/image/pc/mining-lp-top.png"
          alt=""
        />
        <img
          v-else
          src="~@/assets/image/pc/mining-single-top.png"
          class="item-icon"
          alt=""
        />
      </div>
    </div>
    <div class="bottom-table">
      <div class="table-box">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column label="POOL" :width="$isInMobile ? wids : ''">
            <template #default="scope">
              <div class="flex-middle">
                <div class="td-img-box" v-if="scope.row.isLP">
                  <img
                    class="td1-img"
                    src="~@/assets/image/pc/erc20.png"
                    alt=""
                  />
                  <img
                    class="td2-img"
                    src="~@/assets/image/pc/erc20.png"
                    alt=""
                  />
                </div>
                <div class="td-img-box" v-else>
                  <img
                    class="td1-img"
                    src="~@/assets/image/pc/erc20.png"
                    alt=""
                  />
                </div>
                <div class="td-text-box">
                  <div class="symbol" v-if="scope.row.isLP">
                    {{ scope.row.token0Symbol }}/{{ scope.row.token1Symbol }}
                  </div>
                  <div class="symbol" v-else>
                    {{ scope.row.stakingTokenSymbol }}
                  </div>
                  <!-- <div class="label">24x</div> -->
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="apy"
            label="APY"
            :width="$isInMobile ? wids : ''"
          >
          </el-table-column>
          <el-table-column
            prop="tvl"
            label="TVL"
            :width="$isInMobile ? wids : ''"
          >
          </el-table-column>
          <el-table-column
            prop="earned"
            :label="$t('可領取')"
            :width="$isInMobile ? wids : ''"
          >
          </el-table-column>
          <el-table-column :label="$t('操作')" :width="$isInMobile ? wids : ''">
            <template #default="scope">
              <div class="flex-middle">
                <div class="setting-btn-plain"></div>
                <el-button
                  size="small"
                  plain
                  round
                  type="primary"
                  :disabled="scope.row.loading"
                  :loading="scope.row.loading"
                  @click="getReward(scope.row)"
                  >{{ $t("領取") }}</el-button
                >
                <el-button
                  size="small"
                  round
                  type="primary"
                  @click="toPledge(scope.row)"
                  >{{ $t("挖礦") }}</el-button
                >
                <el-button
                  size="small"
                  round
                  type="primary"
                  @click="toWithdraw(scope.row)"
                  >{{ $t("贖回") }}</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch } from "vue";
import { useStore } from "vuex";
import LPStake from "@/utils/LPStake";
import RouterPathAbi from "@/utils/routerPathAbi";
import { config } from "@/utils/config";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import BigNumber from "bignumber.js";
import WalletContract from "@/components/WalletContract";
export default {
  components: {
    WalletContract
  },
  setup() {
    onBeforeRouteUpdate(to => {
      data.type = to.query.type || "1";
      data.initData();
    });
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const data = reactive({
      type: route.query.type || "1",
      web3: computed(() => store.state.provider),
      address: computed(() => store.state.accounts[0]),
      LPStakeContract: null,
      routerPathContract: null,
      wids: "200",
      tableData: [],
      totalStakeUSDT: "",
      initContract: () => {
        data.routerPathContract = new data.web3.eth.Contract(
          RouterPathAbi,
          config.routerPath
        );
        data.LPStakeContract = new data.web3.eth.Contract(
          LPStake,
          config.LPStake
        );
        data.initData();
      },
      initData: () => {
        data.getAllStakeType();
      },
      getAllStakeType: async () => {
        let result = await data.LPStakeContract.methods
          .getAllStakeType()
          .call();
        data.tableData = [];
        let totalStakeUSDT = 0;
        console.log(result);
        for (let i = 0; i < result.length; i++) {
          let parmas = null;
          if (data.type == "1" && result[i].isLP) {
            // LP
            // LP的價值
            let LPUSDTAmount = await data.routerPathContract.methods
              .getLpSwapToken(
                result[i].stakingToken,
                result[i].totalSupply,
                config.USDQAddress
              )
              .call();
            totalStakeUSDT = new BigNumber(LPUSDTAmount)
              .plus(totalStakeUSDT)
              .toFixed();
            // 產出幣的價值
            let apy = "";
            if (new BigNumber(LPUSDTAmount).gt(0)) {
              let TokenUSDTAmount = await data.routerPathContract.methods
                .getBestPathAmount(
                  result[i].rewardsToken,
                  config.USDQAddress,
                  new BigNumber(1)
                    .multipliedBy(Math.pow(10, result[i].rewardsTokenDecimals))
                    .toFixed()
                )
                .call();
              // 日產出
              let TokenDayAmount = new BigNumber(result[i].rewardPerTime)
                .multipliedBy(86400)
                .toFixed();
              // 年利率
              apy = new BigNumber(TokenDayAmount)
                .multipliedBy(TokenUSDTAmount)
                .dividedBy(LPUSDTAmount)
                .multipliedBy(365)
                .multipliedBy(100)
                .dividedBy(Math.pow(10, 18))
                .toFixed(2, 1);
            }
            let earned = await data.LPStakeContract.methods
              .earned(result[i].stakingToken, data.address)
              .call();
            parmas = {
              stakingToken: result[i].stakingToken,
              stakingTokenSymbol: result[i].stakingTokenSymbol,
              token0Symbol: result[i].token0Symbol,
              token1Symbol: result[i].token1Symbol,
              isLP: result[i].isLP,
              apy: (apy || "0.00") + "%",
              tvl:
                new BigNumber(LPUSDTAmount)
                  .dividedBy(Math.pow(10, 18))
                  .toFixed(2, 1) + "U",
              earned: new BigNumber(earned)
                .dividedBy(Math.pow(10, result[i].stakingTokenDecimals))
                .toFixed(2, 1)
            };
          }
          if (data.type == "2" && !result[i].isLP) {
            // 單幣
            // LP的價值
            let LPUSDTAmount = await data.routerPathContract.methods
              .getBestPathAmount(
                result[i].stakingToken,
                config.USDQAddress,
                result[i].totalSupply
              )
              .call();
            totalStakeUSDT = new BigNumber(LPUSDTAmount)
              .plus(totalStakeUSDT)
              .toFixed();
            // 產出幣的價值
            let apy = "";
            if (new BigNumber(LPUSDTAmount).gt(0)) {
              let TokenUSDTAmount = await data.routerPathContract.methods
                .getBestPathAmount(
                  result[i].rewardsToken,
                  config.USDQAddress,
                  new BigNumber(1)
                    .multipliedBy(Math.pow(10, result[i].rewardsTokenDecimals))
                    .toFixed()
                )
                .call();
              // 日產出
              let TokenDayAmount = new BigNumber(result[i].rewardPerTime)
                .multipliedBy(86400)
                .toFixed();
              // 年利率
              apy = new BigNumber(TokenDayAmount)
                .multipliedBy(TokenUSDTAmount)
                .dividedBy(LPUSDTAmount)
                .multipliedBy(365)
                .multipliedBy(100)
                .dividedBy(Math.pow(10, 18))
                .toFixed(2, 1);
            }
            let earned = await data.LPStakeContract.methods
              .earned(result[i].stakingToken, data.address)
              .call();
            parmas = {
              stakingToken: result[i].stakingToken,
              stakingTokenSymbol: result[i].stakingTokenSymbol,
              token0Symbol: result[i].token0Symbol,
              token1Symbol: result[i].token1Symbol,
              isLP: result[i].isLP,
              apy: (apy || "0.00") + "%",
              tvl:
                new BigNumber(LPUSDTAmount)
                  .dividedBy(Math.pow(10, 18))
                  .toFixed(2, 1) + "U",
              earned: new BigNumber(earned)
                .dividedBy(Math.pow(10, result[i].stakingTokenDecimals))
                .toFixed(2, 1)
            };
          }
          if (parmas) {
            data.tableData.push(parmas);
          }
        }
        // 總鎖倉量
        data.totalStakeUSDT = new BigNumber(totalStakeUSDT)
          .dividedBy(10 ** 18)
          .toFixed(2, 1);
      },
      getReward: item => {
        item.loading = true;
        data.LPStakeContract.methods
          .getReward(item.stakingToken)
          .estimateGas({ from: data.address })
          .then(gas => {
            console.log("gas", gas);
            data.LPStakeContract.methods
              .getReward(item.stakingToken)
              .send({ from: data.address, gas: gas })
              .then(() => {
                item.loading = false;
                item.earned = "0.00";
              })
              .catch(() => {
                item.loading = false;
              });
          })
          .catch(err => {
            item.loading = false;
            console.log(err);
          });
      },
      toPledge: item => {
        if (item.isLP) {
          router.push({
            name: "Pledge",
            params: {
              type: 1,
              name: `${item.token0Symbol}/${item.token1Symbol}`,
              token: item.stakingToken
            }
          });
        } else {
          router.push({
            name: "Pledge",
            params: {
              type: 2,
              name: item.stakingTokenSymbol,
              token: item.stakingToken
            }
          });
        }
      },
      toWithdraw: item => {
        if (item.isLP) {
          router.push({
            name: "Withdraw",
            params: {
              type: 1,
              name: `${item.token0Symbol}/${item.token1Symbol}`,
              token: item.stakingToken
            }
          });
        } else {
          router.push({
            name: "Withdraw",
            params: {
              type: 2,
              name: item.stakingTokenSymbol,
              token: item.stakingToken
            }
          });
        }
      }
    });
    if (data.address) {
      data.initContract();
    }
    watch(
      () => data.address,
      val => {
        if (val) {
          data.initContract();
        }
      }
    );
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss" scoped>
.top-row {
  margin: -100px auto 0;
  max-width: 1400px;
  background: linear-gradient(0deg, #e1edff 0%, #ffffff 100%);
  border-radius: 20px;
  height: 202px;
  box-sizing: border-box;
  padding: 0 32px;
  .top-row-left {
    .text1 {
      font-size: 16px;
    }
    .text2 {
      font-size: 22px;
      margin-top: 18px;
    }
    .row-btn-box {
      margin-top: 30px;
      .btn-item {
        width: 190px;
        line-height: 50px;
        text-align: center;
        border-radius: 25px;
        font-size: 22px;
        color: #fff;
        cursor: pointer;
        &.bg-primary {
          background: #0956d0;
        }
        &.bg-orange {
          background: #ffb400 !important;
        }
      }
    }
  }
  .top-row-right {
    margin: 0 120px 0 0;
    img {
      display: block;
    }
  }
}
.address-list {
  max-width: 1400px;
  margin: 30px auto 80px;
}
.bottom-table {
  margin: 30px auto;
  max-width: 1400px;
  .table-box {
    margin: 0 auto;
    padding: 30px 30px 0;
    .td-img-box {
      display: flex;
      align-items: center;
      .td1-img {
        display: flex;
        width: 34px;
        height: 34px;
        position: relative;
        z-index: 1;
        margin-right: 10px;
      }
      .td2-img {
        display: flex;
        width: 34px;
        height: 34px;
        position: relative;
        left: -20px;
      }
    }
    .td-text-box {
      .label {
        display: inline;
        padding: 2px 6px;
        background: rgba(9, 86, 208, 0.1);
        color: #0956d0;
        font-size: 14px;
        border-radius: 2px;
      }
    }
    .td1-span {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 480px) {
  .top-row .top-row-left .row-btn-box .btn-item {
    width: 96px;
    line-height: 34px;
    font-size: 14px;
  }
  .top-row {
    margin-top: 30px;
    .top-row-right {
      // width: 82.5px;
      // height: 141px;
      margin: 0;
      .item-icon {
        width: 82.5px;
      }
    }
  }
  .top-row {
    width: 90%;
    padding: 0 15px;
    height: 142px;

    background: linear-gradient(180deg, #e1edff 45%, #ffffff 100%);
  }
  .top-row .top-row-left .text1 {
    font-size: 13px;
  }
  .top-row .top-row-left .text2 {
    font-size: 27px;
    font-weight: bold;
  }
  .top-row .top-row-left .row-btn-box {
    margin-top: 10px;
  }
  .top-row .top-row-left .text2 {
    margin-top: 10px;
  }
  .bottom-table {
    margin: 30px 15px;
  }
  /deep/ .el-table {
    border-radius: 15px;
  }
  .el-table__body-wrapper,
  .el-table__footer-wrapper,
  .el-table__header-wrapper {
    border-radius: 15px 15px 0px 0;
  }
  .bottom-table .table-box {
    padding: 0;
  }
  .table-box .el-table thead tr th {
    background: #fff;
  }
  // .top-bg {
  //   background: none;
  // }
  .bottom-table .table-box .td-img-box .td1-img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
    z-index: 111;
  }
  .bottom-table .table-box .td-img-box .td2-img {
    width: 28px;
    height: 28px;
  }
  .symbol {
    font-size: 12px;
  }
  .label {
    font-size: 12px;
  }
  .imgs {
  }
  .bottom-table {
    margin: 15px;
  }
  .top-row {
    margin-top: 15px !important;
  }
}
</style>
